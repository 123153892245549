@tailwind base;

/* Start purging... */
/* Stop purging. */

body {
  height: 100%;
  background-color: #fff;
}

/* ==========================================================================
   GLOBAL/BASE
   ========================================================================== */
body {
  font-family: sans-serif;
}

a,
a > button,
a > span,
a > div {
  transition: all 0.15s ease-in-out;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

/* ==========================================================================
   CUSTOM/COMPONENTS
   ========================================================================== */

/* === freedom dividend === */
.c-section-form {
  position: relative;
  overflow: hidden;
}

.c-section-form__panel {
  position: relative;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  padding: 0 5px 5px 5px;
}

.c-section-form__panel.is-done,
.c-section-form__panel.is-inactive {
  position: absolute;
  top: 0;
}

.c-section-form__panel.is-active {
  left: 0;
}

.c-section-form__panel.is-done {
  left: -100%;
}

.c-section-form__panel.is-inactive {
  left: 100%;
}

.c-section-form__bg-gradient {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1.5rem;
  z-index: 2;
}

.c-section-form__bg-gradient.west {
  left: 0;

  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.c-section-form__bg-gradient.east {
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

/* ==== even form ==== */

.c-input-email {
  padding-left: 3.5rem;
  background-image: url(/static/icons/icon-mail-black-25.svg);
  background-size: 2rem auto;
  background-position: 0.75rem center;
  background-repeat: no-repeat;
}

.c-input-phone {
  padding-left: 3.5rem;
  background-image: url(/static/icons/icon-phone-black-25.svg);
  background-size: 2rem auto;
  background-position: 0.75rem center;
  background-repeat: no-repeat;
}

.c-input-lock {
  padding-right: 3.5rem;
  background-image: url(/static/icons/icon-lock-detail-gray.svg);
  background-size: 2rem auto;
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
}

.c-input-search {
  background-image: url(/static/icons/icon-magnify-gray.svg);
  background-position: 0.75rem center;
  background-size: 1.75rem 1.75rem;
  background-repeat: no-repeat;
}

/* ==== card cart ==== */
.c-cart {
  bottom: -4rem;
}

.c-cart.is-on {
  bottom: 0;
}

.c-cart__row {
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
}

/* ==== cards row ==== */
@media (min-width: 640px) {
  .c-cards-row__container {
    overflow-y: visible;
    overflow-x: auto;
  }
}

.c-cards-row__container:before,
.c-cards-row__container:after {
  content: '';
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  -webkit-transition: width 0.25s ease-out;
  transition: width 0.25s ease-out;
  z-index: 1;
}

.c-cards-row__container:before {
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.c-cards-row__container:after {
  right: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.15) 100%
  );
}

.c-cards-row__container.fade-start:before {
  width: 1.5rem;
}

.c-cards-row__container.fade-end:after {
  width: 1.5rem;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.c-cards-row__list {
}

/* ==== card ==== */
.c-card {
  display: inline-block;
  flex-shrink: 0;
}

.c-card__art {
}

.c-cards__count,
.c-card__btn-remove {
  top: -0.25rem;
  right: -0.25rem;
}

.c-card__tool-tip,
.c-card__btn-remove {
  display: none;
  opacity: 0;
  max-height: 0;
}

.c-card__tool-tip {
  bottom: 125%;
  left: 0;
}

.c-card__tool-tip-ccontent {
  width: 10rem;
}

.c-card__btn-remove {
  overflow: hidden;
  background-color: rgba(5, 47, 94, 0.75);
}

.c-card__btn-remove:hover {
  background-color: rgba(5, 47, 94, 1);
  display: flex;
  opacity: 1;
  max-height: 10rem;
}

.c-card:hover .c-card__tool-tip,
.c-card:hover .c-card__btn-remove {
  display: flex;
  opacity: 1;
  max-height: 10rem;
}

.c-card .c-arrow-css--south:before,
.c-card .c-arrow-css--south:after {
  left: 30%;
}

/* main fluid container
  ======================================================= */
.container-fluid {
  @apply w-full max-w-6xl mx-auto px-4;
}

@media (min-width: 480px) {
  .container-fluid {
    @apply px-8;
  }
}

@media (min-width: 1152px) {
  .container-fluid {
    @apply px-4;
  }
}

/* btns: main buttons
  ======================================================= */
.c-btn {
  @apply flex items-center justify-center w-full max-w-xs mx-auto px-1 py-2 rounded-sm;
  transition: all 0.15s ease-in-out;
}

.c-btn--primary {
  @apply border border-secondary font-semibold text-white text-sm;
  background-color: #14c741;
  border-color: #14c741;
}

.c-btn--primary:hover {
  @apply shadow-lg;
  background-color: #00da3e;
  border-color: #00da3e;
}

.c-btn--secondary {
  @apply bg-primary-bright border border-primary-bright font-semibold text-white text-sm;
}

.c-btn--secondary:hover {
  @apply bg-primary-mid border-primary-mid;
}

.c-btn--primary-bright {
  @apply bg-primary-bright border border-primary-bright font-bold text-white text-sm;
}

.c-btn--primary-bright:hover {
  background-color: #138adc;
  border-color: #138adc;
}

.c-btn--ghost {
  @apply border border-primary-bright text-primary-bright text-sm;
}

.c-btn--ghost:hover {
  @apply bg-primary-light-bg;
}

.c-btn--ghost-gray {
  @apply border border-gray-400 text-gray-700 text-sm;
}

.c-btn--ghost-gray:hover {
  @apply bg-gray-100;
}

.c-btn--text-lg,
.c-btn--text-lg:hover {
  @apply text-lg;
}

.c-btn--full {
  max-width: none;
}

.c-btn--disable {
  opacity: 0.35;
}

.c-btn--disable.c-btn--primary:hover {
  background-color: #14c741;
  border-color: #14c741;
  cursor: default;
  box-shadow: none;
  cursor: not-allowed;
  box-shadow: none;
}

.c-btn--disable-gray,
.c-btn--disable-gray.c-btn--primary:hover {
  opacity: 1;
  background-color: #e2e8f0;
  border-color: #e2e8f0;
  cursor: not-allowed;
  box-shadow: none;
}

/* card-panel: main product panel
  ======================================================= */
.c-product-panel {
  transition: all 0.25s ease-in-out;
}

.c-highlight__item {
  opacity: 1;
  max-height: 30rem;
  transition: all 0.25s ease-in-out;
  overflow: visible;
}

/* product-details
  ======================================================= */
.c-product-details {
}

/* is-closed */
.c-product-panel.is-closed .c-highlight__item--hidden {
  max-height: 0;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.c-product-panel.is-closed .c-highlight__item--hidden {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* product-details
  ======================================================= */
.c-product-details {
  opacity: 1;
  max-height: 80rem;
  transition: all 0.25s ease-in-out;
  overflow: visible;
}

.c-product-panel--base.is-closed .c-product-details {
  max-height: 0;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.c-product-details__bg-grad {
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8.5rem;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}

/* list-disct: list disc color changes
  ======================================================= */

.c-list-disc {
}

.c-list-disc > li::before,
.c-list-disc__item::before {
  content: '\2022';
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  @apply text-primary-bright;
}

.c-list-disc > li.c-list-disc__item--gold::before,
.c-list-disc__item--gold::before {
  color: #f6b300;
}

.c-list-disc > *:last-child {
  margin-bottom: 0;
}

/* List with green checkmark */
.c-list-check {
}

.c-list-check > li,
.c-list-check__item {
  position: relative;
  margin-bottom: 0.5rem;
}

.c-list-check > li::before,
.c-list-check__item::before {
  content: '';
  position: absolute;
  top: 0.1rem;
  left: -1.75rem;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  font-weight: bold;
  background-image: url(/static/icons/icon-checkmark-secondary.svg);
  background-size: cover;
  background-position: center center;
}

/* Theme: Needs to be added with the base class. List check with blue checkmarks */
.c-list-check--blue {
}
.c-list-check--blue > li::before,
.c-list-check--blue .c-list-check__item::before {
  background-image: url(/static/icons/icon-checkmark-primary-bright.svg);
}

/* card-art: Hover over display
  ======================================================= */

.c-card-title sup {
  font-size: 70%;
}

.c-card-art {
}

.c-card-art__content {
  top: 100%;
  background-color: rgba(2, 27, 55, 0.75);
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.c-card-art:hover .c-card-art__content {
  top: 0;
  opacity: 1;
}

.c-card-art__badge {
  top: -0.5rem;
  left: 0.5rem;
}

/* C-HERO
  ======================================================= */
.c-hero--hpto-light-links .c-product-rating__total {
  color: #ffffff !important;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.c-hero--hide-review-count .c-product-rating__total,
.c-product-rating--hide-review-count .c-product-rating__total {
  display: none;
}

.c-hero--advert-link-bright .c-popup {
  color: #21a3fc;
}
.c-hero--advert-link-bright .c-popup:hover,
.c-hero--advert-link-bright .c-popup:active {
  color: white;
  text-decoration: underline;
}

.c-hero--wf .c-popup {
  color: #d41c2c;
}
.c-hero--wf .c-popup:hover,
.c-hero--wf .c-popup:active {
  color: rgb(141, 4, 15);
}

.c-hero--wf-dark .c-popup {
  color: #da201d;
}
.c-hero--wf-dark .c-popup:hover,
.c-hero--wf-dark .c-popup:active {
  color: #b60805;
}

/* NAV-MAIN--LIGHT: theme used for when navigation needs to be light
  ======================================================= */
/* Have to separate out some CSS from the .c-nav-main--light
   so that it's easier to target some elements */

/* hide the white bbb logos by default */
.c-bbb--minimal--white,
.c-bbb--base--white {
  display: none;
}

/* override some default nav styling */
.c-nav-main--light .c-nav-link {
  color: #ffffff !important;
}

.c-nav-main--light .c-nav-link .c-icon-arrow {
  background-image: url('/static/icons/icon-chevron-d-white.svg') !important;
}

.c-nav-main--light .c-nav-link:hover {
  color: #052f5e !important;
}

.c-nav-main--light .c-nav-link:hover .c-icon-arrow {
  background-image: url('/static/icons/icon-chevron-d-primary.svg') !important;
}

/* hide/show base BBB logo */
.c-nav-main--light .c-bbb--minimal {
  display: none;
}

.c-nav-main--light .c-bbb--minimal--white {
  display: block;
}

/*===== IS-OPEN =====*/
.c-nav-main--light.is-open .c-bbb--minimal {
  display: block;
}

.c-nav-main--light.is-open .c-bbb--minimal--white {
  display: none;
}

/* mix-theme - used for WF Reflect HPTO 2023.01 */
.c-nav-main--mix-theme .c-bbb--minimal {
  display: none;
}
.c-nav-main--mix-theme .c-bbb--minimal--white {
  display: block;
}

@media (min-width: 1024px) {
  .c-nav-main--mix-theme .c-nav-toggle__brdr {
    background-color: #fff;
  }
  .c-nav-main--mix-theme .cc-logo--base {
    display: none;
  }
  .c-nav-main--mix-theme .cc-logo--white {
    display: block;
  }

  .c-nav-main--mix-theme .c-nav-link:hover {
    color: #052f5e !important;
  }
}

/* mix-theme - used for WF Reflect HPTO 2023.10 WF Autograph */
.c-nav-main--mix-theme2 .c-bbb--minimal {
  display: none;
}
.c-nav-main--mix-theme2 .c-bbb--minimal--white {
  display: block;
  opacity: 0.5;
}
@media (min-width: 1024px) {
  .c-nav-main--mix-theme2 .cc-logo--base {
    display: none;
  }
  .c-nav-main--mix-theme2 .cc-logo--white {
    display: block;
  }
}

/*===== 1024PX =====*/
/* At 1024px cleanup the elements so they are consistent
   even when "is-open" class is present */
@media (min-width: 1024px) {
  .c-nav-main--light .c-bbb--base--white {
    display: block;
  }

  /* hide base BBB logos */
  .c-nav-main--light .c-bbb--base,
  .c-nav-main--light.is-open .c-bbb--base,
  .c-nav-main--light .c-bbb--minimal,
  .c-nav-main--light.is-open .c-bbb--minimal {
    display: none;
  }

  /* hide mobile minimal BBB logo */
  .c-nav-main--light .c-bbb--minimal--white,
  .c-nav-main--light.is-open .c-bbb--minimal--white {
    display: none;
  }

  .c-nav-main--light.is-open .c-bbb--base--white {
    display: block;
  }
}

.t-nav-relative #navbar-container {
  position: relative;
}
.t-nav-relative .c-main__container {
  padding-top: 0;
}

/* NAV-MAIN--MINIMAL: theme used for when navigation is set to minimal
  ======================================================= */
/* This is used to created a walled garden effect where users
   have less exit points on the page  */
.c-nav-main--minimal {
}

.c-nav-main__as-seen {
  display: none;
}

.c-nav-main--minimal .c-nav-main__col {
  display: none;
}

.c-nav-main--minimal .c-nav-main__logo {
  padding-left: 1rem;
  order: 1;
}

.c-nav-main--minimal .c-nav-main__bbb-mobile {
  width: auto;
  padding-right: 1rem;
  order: 2;
}

.c-nav-main--dark-blue .c-nav-main {
  background-color: #052f5e;
}
.c-nav-main--dark-blue .cc-logo.cc-logo--base,
.c-nav-main--dark-blue .c-nav-main__as-seen,
.c-nav-main--dark-blue .c-bbb--minimal {
  display: none !important;
}
.c-nav-main--dark-blue .cc-logo.cc-logo--white,
.c-nav-main--dark-blue .c-bbb--minimal--white {
  display: block;
}
@media (min-width: 1024px) {
  .c-nav-main--dark-blue .c-nav-main__bbb-mobile {
    display: block;
  }
}

@media (min-width: 1024px) {
  .c-nav-main--minimal .c-nav-main__logo {
    padding-left: 0;
  }

  .c-nav-main--minimal .c-nav-main__lg-links {
    display: none;
  }

  .c-nav-main--minimal .c-nav-main__as-seen {
    display: flex;
    order: 4;
  }
  .c-nav-main--minimal-gray .c-nav-main__as-seen img {
    opacity: 0.4;
    filter: grayscale(100%);
  }
  .c-nav-main--minimal-gray
    .c-nav-main__as-seen
    img.c-nav-main__as-seen-inc-5000 {
    opacity: 1;
  }
}

/* NAV-MAIN--MINIMAL-BBB: theme used for when navigation is set to minimal
  ======================================================= */
/* This is used to created a walled garden effect where users
   have less exit points on the page  */
.c-nav-main--minimal-bbb {
}
.c-nav-main--minimal-bbb .c-nav-main__col,
.c-nav-main--minimal-bbb .c-nav-main__lg-links,
.c-nav-main--minimal-bbb .c-nav-main__as-seen {
  display: none;
}
.c-nav-main--minimal-bbb .c-nav-main__logo {
  padding-left: 1rem;
  order: 1;
}

.c-nav-main--minimal-bbb .c-nav-main__bbb-mobile {
  width: auto;
  padding-right: 1rem;
  order: 2;
}

@media (min-width: 1024px) {
  .c-nav-main--minimal-bbb .c-nav-main__logo {
    padding-left: 0;
  }
  .c-nav-main--minimal-bbb .c-nav-main__col {
    display: flex;
    order: 4;
  }
}

/* NAV-MAIN--MINIMAL: theme used for when navigation is set to minimal
  ======================================================= */
/* This was created for a single tip template for Maven  */
.c-nav-main--empty {
}

.c-nav-main--empty .c-nav-main__col,
.c-nav-main--empty .c-nav-main__bbb-mobile,
.c-nav-main--empty .c-nav-main__lg-links {
  display: none;
}

.c-nav-main--empty .c-nav-main__logo {
  padding-left: 1rem;
  order: 1;
}

@media (min-width: 1024px) {
  .c-nav-main--empty .c-nav-main__logo {
    padding-left: 0;
  }
}

/* 2023.8: EXP-194 Advertiser Disclosure */

.c-nav-main--474-v1.c-nav-main--474-v1--as-seen-in .c-disclosure-wrap,
.c-nav-main--474-v1.c-nav-main--474-v1--empty .c-disclosure-wrap,
.c-nav-main--474-v2 .c-disclosure-wrap {
  display: none;
}
.c-nav-main--474-v1.c-nav-main--474-v1--as-seen-in .c-bbb__disclaimer-wrap,
.c-nav-main--474-v1.c-nav-main--474-v1--empty .c-bbb__disclaimer-wrap {
  display: flex;
}

.c-nav-main--474-v1.c-nav-main--474-v1--empty .c-nav-main__inner {
  justify-content: space-between;
}

.c-nav-main--474-v1.c-nav-main--474-v1--empty .c-nav-main__bbb-mobile {
  display: flex;
  width: inherit;
  order: 2;
  justify-content: flex-end;
  padding-right: 1rem;
}

.c-nav-main--474-v1.c-nav-main--474-v1--empty .c-bbb {
  display: none;
}

.c-nav-main--474-v1.c-nav-main--474-v1--empty .c-bbb__disclaimer-wrap {
  display: block;
  margin-top: 2rem;
}

@media (min-width: 1024px) {
  .c-nav-main--474-v1.c-nav-main--474-v1--empty .c-nav-main__bbb-mobile {
    padding-right: 0;
  }
  .c-nav-main--474-v1.c-nav-main--474-v1--empty .c-bbb__disclaimer-wrap {
    margin-top: 2.1rem;
  }
  .c-nav-main--474-v1.c-nav-main--474-v1--default .c-disclosure-wrap {
    position: fixed;
    top: 2.6rem;
    right: 2rem;
    z-index: 61;
  }
  .c-nav-main--474-v1.c-nav-main--474-v1--default .c-nav-main__bbb {
    margin-top: -1rem;
  }

  .c-nav-main--474-v1.c-nav-main--474-v1--default
    .c-advert-disclosure
    .c-popup__container {
    left: auto;
    right: 0;
  }

  .c-nav-main--474-v1.c-nav-main--474-v1--default
    .c-advert-disclosure
    .c-popup__arrow:before,
  .c-nav-main--474-v1.c-nav-main--474-v1--default
    .c-advert-disclosure
    .c-popup__arrow:after {
    left: auto;
  }
}

/* 2024.6: EXP-275 Category Page Disclosure */
/* ===== THEME ===== */
@media (min-width: 768px) {
  .c-cate-masthead--center .c-cate-masthead__header {
    text-align: center;
  }
  .c-cate-masthead--center .c-cate-masthead__content {
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  .c-cate-masthead--center.c-cate-masthead {
    padding-left: 0 !important;
  }
}

/* cate-icon: category icons used on navigation
  ======================================================= */
.c-cate-icon {
  background-image: url('/static/sprites/sprite-categories.svg');
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.c-cate-icon.balance-transfer {
  background-position: center 0;
}

.c-cate-icon.business {
  background-position: center -1.75rem;
}

.c-cate-icon.cash-back {
  background-position: center -3.5rem;
}

.c-cate-icon.dining {
  background-position: center -5.25rem;
}

.c-cate-icon.emv-chip {
  background-position: center -7rem;
}

.c-cate-icon.gas {
  background-position: center -8.75rem;
}

.c-cate-icon.low-interest {
  background-position: center -10.5rem;
}

.c-cate-icon.no-annual-fee {
  background-position: center -12.25rem;
}

.c-cate-icon.no-foreign-fees {
  background-position: center -14rem;
}

.c-cate-icon.prepaid {
  background-position: center -15.75rem;
}

.c-cate-icon.rewards {
  background-position: center -17.5rem;
}

.c-cate-icon.secured {
  background-position: center -19.25rem;
}

.c-cate-icon.student {
  background-position: center -21rem;
}

.c-cate-icon.top-cc {
  background-position: center -22.75rem;
}

.c-cate-icon.travel {
  background-position: center -24.5rem;
}

.c-cate-icon.credit-excellent {
  background-position: center -26.25rem;
}

.c-cate-icon.credit-good {
  background-position: center -28rem;
}

.c-cate-icon.credit-fair {
  background-position: center -29.75rem;
}

.c-cate-icon.credit-poor {
  background-position: center -31.5rem;
}

.c-cate-icon.credit-limited {
  background-position: center -33.25rem;
}

.c-cate-icon.american-express {
  background-position: center -35rem;
}

.c-cate-icon.boa {
  background-position: center -36.75rem;
}

.c-cate-icon.capital-one {
  background-position: center -38.5rem;
}

.c-cate-icon.chase {
  background-position: center -40.25rem;
}

.c-cate-icon.citi {
  background-position: center -42rem;
}

.c-cate-icon.discover {
  background-position: center -43.75rem;
}

.c-cate-icon.mastercard {
  background-position: center -45.5rem;
}

.c-cate-icon.visa {
  background-position: center -47.25rem;
}

.c-cate-icon.wells-fargo {
  background-position: center -49rem;
}

.c-cate-icon.card-refi {
  background-position: center -50.75rem;
}

.c-cate-icon.debt-consolidation {
  background-position: center -52.5rem;
}

.c-cate-icon.personal-loan {
  background-position: center -54.25rem;
}

/* category alert bar
  ======================================================== */
.c-site-alert {
}

.c-site-alert.is-hidden {
  max-height: 0;
  overflow: hidden;
}

.c-site-alert.is-shown {
  max-height: 30rem;
}

/*===== LOGO =====*/
.cc-logo--white {
  display: none;
}

/*===== NAV THEMES =====*/
.c-nav-main--shadow {
  box-shadow: 0 4px 8px -3px rgba(0, 0, 0, 0.12);
}

.c-nav-main--shadow.z-70 {
  z-index: 70;
}

.c-nav-main--transparent {
  box-shadow: none;
  background-color: transparent;
}

.c-nav-main--transparent-lightblue {
  background-color: rgba(240, 250, 255, 0.25);
}

.c-nav-main--transparent.is-open {
  background-color: #ffffff;
  box-shadow: 0 4px 8px -3px rgba(0, 0, 0, 0.12);
}

/*===== NAV-TOGGLE =====*/
.c-nav-toggle {
  position: relative;
}

.c-nav-toggle__inner {
  width: 1.75rem;
  height: 1.75rem;
}

.c-nav-toggle__brdr {
  transition:
    opacity 0.125s ease-in-out,
    transform 0.125s ease-in-out;
}

.c-nav-toggle__brdr {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: #052f5e;
  opacity: 0.75;
}

.c-nav-toggle__brdr.first {
  top: 5px;
}

.c-nav-toggle__brdr.mid {
  top: 13px;
}

.c-nav-toggle__brdr.last {
  top: 21px;
}

@media (min-width: 375px) {
  .c-nav-toggle__inner {
    width: 2rem;
    height: 2rem;
  }

  .c-nav-toggle__brdr.first {
    top: 8px;
  }

  .c-nav-toggle__brdr.mid {
    top: 16px;
  }

  .c-nav-toggle__brdr.last {
    top: 24px;
  }
}

/*=== is-open ===*/
.c-nav-toggle.is-open .c-nav-toggle__brdr {
  opacity: 1;
}

.c-nav-toggle.is-open .c-nav-toggle__brdr.first {
  top: 50%;
  transform: rotate(45deg);
}

.c-nav-toggle.is-open .c-nav-toggle__brdr.mid {
  transform: scale(1.5);
  opacity: 0;
}

.c-nav-toggle.is-open .c-nav-toggle__brdr.last {
  bottom: inherit;
  top: 50%;
  transform: rotate(-45deg);
}

/*===== NAV LIGHT THEME =====*/
/* This is used on the home page hero section when there's
a dark background for a HPTO */

.c-nav-main--light {
}

.c-nav-main--light .cc-logo--base {
  display: none;
}

.c-nav-main--light .cc-logo--white {
  display: block;
}

/* Burger Menu */
.c-nav-main--light .c-nav-toggle__brdr {
  background-color: #fff;
}

/* IS-OPEN */
.c-nav-main--light.is-open .cc-logo--base {
  display: block;
}

.c-nav-main--light.is-open .cc-logo--white {
  display: none;
}

.c-nav-main--light.is-open .c-nav-toggle__brdr {
  background-color: #052f5e;
}

@media (min-width: 1024px) {
  .c-nav-main--light.is-open .cc-logo--white {
    display: block;
  }

  .c-nav-main--light.is-open .cc-logo--base {
    display: none;
  }
}

/* product rating: star ratings
  ======================================================= */
.c-product-rating {
}

.c-product-rating__list {
}

.c-product-rating__item {
  margin-left: -2px;
}

.c-product-rating__star {
  background-image: url('/static/icons/icon-star-empty.svg');
}

.c-product-rating__star.full {
  background-image: url('/static/icons/icon-star-full.svg');
}

.c-product-rating__star.half {
  background-image: url('/static/icons/icon-star-half.svg');
}

/* rating popup: hover over for user reviews
  ======================================================= */
.c-rating-popup {
  display: none;
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
  width: 20rem;
  transition: all 0.2s ease-in-out;
}

.c-rating-popup.c-review-num {
  left: -3.8rem;
}

.c-rating-popup.c-column {
  left: -6.6rem;
}

.c-rating-popup.c-arrow-css:before,
.c-rating-popup.c-arrow-css:after {
  left: 50%;
}

/* ====== THEME ====== */
/* Used for to position review popup to appear at the top
of the stars, not the bottom. Currently used on exp mobile upsell */
.c-card-upsell--mobile .c-rating-popup--top {
  left: 4rem;
}
.c-rating-popup--top {
  top: inherit;
  bottom: 2.75rem;
  left: -4rem;
}

.c-rating-popup--top .c-arrow-css:after,
.c-rating-popup--top .c-arrow-css:before {
  top: 100%;
  bottom: inherit;
}

.c-product-rating__total--white .c-product-rating__total {
  color: #ffffff !important;
  opacity: 0.6;
  font-weight: normal;
}

/* arrow: used on tooltips, hoverovers
  ======================================================= */
.c-arrow-css:after,
.c-arrow-css:before {
  bottom: 100%;
  left: 15%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.c-arrow-css:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 6px;
  margin-left: -6px;
}

.c-arrow-css:before {
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-width: 7px;
  margin-left: -7px;
}

@media (min-width: 768px) {
  .c-arrow-css:after,
  .c-arrow-css:before {
    left: inherit;
    right: 15%;
  }
}

/* South */
.c-arrow-css--south:after,
.c-arrow-css--south:before {
  top: 100%;
  left: 25%;
}

.c-arrow-css--south:after {
  border-top-color: #ffffff;
  border-bottom-color: transparent;
}

.c-arrow-css--south:before {
  border-top-color: rgba(0, 0, 0, 0.15);
  border-bottom-color: transparent;
}

/* West */
.c-arrow-css--west:after,
.c-arrow-css--west:before {
  right: 100%;
  top: 1.5rem;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.c-arrow-css--west:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 8px;
  margin-top: -8px;
}
.c-arrow-css--west:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: rgba(0, 0, 0, 0.15);
  border-width: 9px;
  margin-top: -9px;
}

/* icon-arrow */
.c-icon-arrow {
  @apply inline-block bg-no-repeat bg-transparent bg-cover;
  background-image: url('/static/icons/icon-chevron-d-black.svg');
}

/* desktop navigation dropdown */
.c-nav-dropdown-link.c-arrow-css:before,
.c-nav-dropdown-link.c-arrow-css:after {
  left: 47%;
  bottom: inherit;
  z-index: 1;
}
.c-nav-dropdown-link.c-arrow-css:before {
  top: 158%;
}
.c-nav-dropdown-link.c-arrow-css:after {
  top: 172%;
}

/* header: used on card category pages
  ======================================================= */
.c-popup__container {
  top: 125%;

  min-width: 16rem;
  transition: all 0.15s ease-in-out;
}

@media (min-width: 480px) {
  .c-popup__container {
    min-width: 24rem;
  }
  .xs\:c-popup__container--slim {
    min-width: 16rem;
  }
}

.c-popup .c-popup__container {
  padding-top: 0.35rem;
  max-height: 20rem;
  overflow: visible;
}

.c-popup__content {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.c-popup__arrow:after,
.c-popup__arrow:before {
  bottom: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.c-popup__arrow:after {
  right: 64px;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 6px;
  margin-left: -6px;
}

.c-popup__arrow:before {
  right: 62px;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-width: 8px;
  margin-left: -8px;
}

@media (min-width: 640px) {
  .c-popup__arrow:after,
  .c-popup__arrow:before {
    left: inherit;
  }
}

.t-disclosure-left .c-popup__container {
  left: 0;
}

.t-disclosure-left .c-popup__arrow:before,
.t-disclosure-left .c-popup__arrow:after {
  left: 5rem;
}

.t-disclosure-right .c-popup__container {
  left: auto;
  right: 0;
}

.t-disclosure-right .c-popup__arrow:before,
.t-disclosure-right .c-popup__arrow:after {
  left: auto;
}

/* copy
  ======================================================= */
.c-copy {
}

.c-copy h1,
.c-copy h2,
.c-copy h3,
.c-copy h4,
.c-copy h5,
.c-copy h6 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.25;
}

.c-copy h3,
.c-copy b {
  color: #052f5e;
}

.c-copy h3 {
  font-size: 1.875rem;
  font-family: --font-inria-serif, Georgia, serif;
}

.c-copy a {
  color: #21a3fc;
  text-decoration: underline;
}

.c-copy a:hover {
  color: #0047ab;
}

.c-copy b,
.c-copy strong,
.c-copy i,
.c-copy h3 {
  font-weight: bold;
}

.c-copy em,
.c-copy i {
  font-style: italic;
}

.c-copy p,
.c-copy ul,
.c-copy ol,
.c-copy table,
.c-copy dl {
  margin-bottom: 1.25rem;
}

.c-copy ol,
.c-copy ul {
  padding-left: 1rem;
}

.c-copy ol {
  list-style-type: decimal;
}

.c-copy ul {
  list-style-type: disc;
}

.c-copy li,
.c-copy dt,
.c-copy dl {
  margin-bottom: 0.25rem;
}

.c-copy .mb-0 {
  margin-bottom: 0;
}

.c-copy .mb-0\.5 {
  margin-bottom: 0.125rem;
}

.c-copy .c-list-disc > li::before,
.c-copy .c-list-disc__item::before {
  content: '\2022';
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  @apply text-primary-bright;
}

.c-copy table {
  table-layout: fixed;
}

.c-copy thead th {
  background-color: #f7f7f7;
}

.c-copy table th,
.c-copy table td {
  padding: 0.25rem;
  border: 1px solid #f7f7f7;
}

.c-copy pre {
  /* font-family: "Courier New", Courier, monospace; */

  width: 100%;
  max-width: 100vw;
  padding: @global-padding-unit-smaller;
  margin-bottom: @global-margin-unit;

  resize: both;

  color: #666;
  background: @gray-color-98;
  border: 1px solid @global-border-color;
  border-radius: @global-border-color;
  min-width: 250px;
  overflow: auto;
}

.c-copy code {
  color: #666;
  background: 0 0;
  text-shadow: 0 1px #fff;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: @line-height-base;

  -moz-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

.c-table-wrap {
  overflow: auto;
}

/* EXP 2022.10: Text Truncation */
.c-card-content.c-slidedown > p:first-child {
  margin-top: 0;
}

/* C-CONTENT-HIGHLIGHT
// Component for tips v2
  ======================================================= */
.c-content-highight__content p,
.c-content-highight__content ul {
  margin-bottom: 0.75em;
}
.c-content-highight__content *:last-child {
  margin-bottom: 0;
}
.c-content-highight__content ul {
  padding-left: 1.5rem;
  list-style: none;
}
.c-content-highight__content li::before {
  content: '\2022';
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  @apply text-tetriary-dark;
}

/* C-MODAL-CARD__EXIT-CONTENT
// Exit modal for tips v2
  ======================================================= */
.c-modal-card__exit-content *:last-child {
  margin-bottom: 0;
}
.c-modal-card__exit-content ul {
  padding-left: 1.5rem;
  list-style: none;
}
.c-modal-card__exit-content li {
  margin-bottom: 0.5em;
}
.c-modal-card__exit-content li::before {
  content: '\2022';
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  @apply text-tetriary-dark;
}

/* C-MODAL-LB
// Leave behind card modal
  ======================================================= */
.c-modal-lb__content strong span {
  text-decoration: none !important;
  border-bottom: 2px solid #f6b300;
  color: #052f5e;
}
.c-modal-lb--cap-one-shopping .c-modal-lb__name {
  display: none;
}

/* C-TEXT-STYLE
// Used on tips v2
  ======================================================= */
.c-text-style--lg .col-span-12 > div > p,
.c-text-style--lg .col-span-12 > div > dl,
.c-text-style--lg .col-span-12 > div > ol,
.c-text-style--lg .col-span-12 > div > ul,
.c-text-style--lg .col-span-12 > div > table {
  margin-bottom: 1rem;
}
.c-text-style--lg .col-span-12 > div > ul,
.c-text-style--lg .col-span-12 > div > li {
  line-height: 1.8;
}
.c-text-style--lg .c-card-tile__header {
  font-size: 1.5rem; /* 20px */
}

/* C-STATUS GAUGE
// Used in Even form flow
  ======================================================= */
.c-status-gauge {
  position: relative;
  height: 8px;
  background: #f2f2f2;
}

.c-status-gauge--vw-100 {
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.c-status-gauge__fill {
  position: relative;
  height: 8px;
  border-radius: 0 400px 400px 0;
  background-color: #fdca41;
  z-index: 1;
  transition-property: all;
  transition-duration: 0.25s;
}

@media (min-width: 1024px) {
  .c-status-gauge {
    height: 8px;
  }
  .c-status-gauge__fill {
    top: -3px;
    height: 12px;
    border-radius: 400px;
  }
}

/* security cta
  ======================================================= */
.gradient-bg {
  background: linear-gradient(220.09deg, #f2fff3 0%, #f5fafc 100%);
}

.bg-gradient-blue-green-270 {
  background: linear-gradient(
    270deg,
    rgba(192, 243, 196, 0.15) 13.78%,
    rgba(240, 250, 255, 1) 100%
  );
}

/* Golden Ribbon
  ======================================================= */
.c-ribbon {
  height: 34px;
}

.c-ribbon:before,
.c-ribbon:after {
  content: '';
  border-style: solid;
  border-width: 0;
  height: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.c-ribbon:before {
  left: -17px;
  border-color: #fdca41 transparent;
  border-width: 17px 0 17px 17px;
}

.c-ribbon:after {
  right: -17px;
  border-color: #fdca41 transparent;
  border-width: 17px 17px 17px 0;
}

.c-ribbon--primary-bright:before {
  border-color: #21a3fc transparent;
}

.c-ribbon--primary-bright:after {
  border-color: #21a3fc transparent;
}

/* Themes */
.c-ribbon--slim {
  height: 28px;
  line-height: 1;
}

.c-ribbon--slim:before {
  left: -14px;
  border-width: 14px 0 14px 14px;
}

.c-ribbon--slim:after {
  right: -14px;
  border-width: 14px 14px 14px 0;
}

.c-ribbon--slim-24 {
  height: 24px;
  line-height: 1;
}

.c-ribbon--slim-24:before {
  left: -12px;
  border-width: 12px 0 12px 12px;
}

.c-ribbon--slim-24:after {
  right: -12px;
  border-width: 12px 12px 12px 0;
}

.c-ribbon--slimmer {
  height: 20px;
  line-height: 1;
}

.c-ribbon--slimmer:before {
  left: -9px;
  border-width: 10px 0 10px 10px;
}
.c-ribbon--slimmer:after {
  right: -9px;
  border-width: 10px 10px 10px 0;
}

.c-ribbon--rt:before {
  content: none;
}

@media (min-width: 640px) {
  .sm\:c-ribbon--rt:before {
    content: none;
  }
}

.c-ribbon--rt:before {
  content: none;
}
@media (min-width: 768px) {
  .md\:c-ribbon--rt:before {
    content: none;
  }
}

/* RIBBON USING A BG IMAGE */
.c-ribbon-bg-rt {
  background-image: url(/static/images/bg-ribbon-rt-tetriary-2.png);
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
}
.c-ribbon-bg-rt--primary-bright {
  background-image: url(/static/images/bg-ribbon-rt-primary-bright-2.png);
}

/* compare button and  toggle
  ======================================================= */

.c-compare-btn {
  line-height: 1.1;
}

/* .c-compare-btn:hover .c-compare-toggle__rect-obj {
  stroke: #0047ab;
} */
/* HOVER */
.c-compare-btn:hover {
  color: #21a3fc;
}

.c-compare-btn:hover .c-compare-toggle__box-line {
  stroke-dashoffset: 0;
}

.c-compare-btn:hover .c-compare-toggle__box-line.first {
  stroke: #3ed14a;
}

.c-compare-btn:hover .c-compare-toggle__box-line.last {
  stroke: #21a3fc;
}

.c-compare-btn:hover .c-compare-toggle__tick-path.hover-tick {
  stroke-dashoffset: 0;
}

/* ADDED */
.c-compare-btn--added .c-compare-toggle__rect-obj {
  stroke: #21a3fc;
}

.c-compare-btn--added .c-compare-toggle__tick-path.active-tick {
  stroke-dashoffset: 0;
}

/* ADDED HOVER */
.c-compare-btn--added:hover {
  color: #0047ab;
}

.c-compare-btn--added:hover .c-compare-toggle__box-line.first {
  stroke: #0047ab;
}

.c-compare-btn--added:hover .c-compare-toggle__box-line.last {
  stroke: #0047ab;
}

.c-compare-btn--added:hover .c-compare-toggle__tick-path.active-tick {
  stroke: #0047ab;
}

.c-compare-toggle__icon {
}

.c-compare-toggle__tick {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
}

.c-compare-toggle__rect-obj {
  fill: transparent;
  stroke-width: 4px;
  stroke: #ccc;
  z-index: 0;
  position: relative;
  transition: all 0.15s ease-in-out;

  /* transition: stroke-dashoffset 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); */
}

.c-compare-toggle__box-line {
  position: relative;
  z-index: 1;
  stroke-width: 4px;

  stroke-dasharray: 42;
  stroke-dashoffset: 42;

  transition: stroke-dashoffset 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-compare-toggle__tick-path {
  fill: none;
  stroke: #000;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;

  opacity: 1;
  stroke-dasharray: 16;
  stroke-dashoffset: 16;

  transition: stroke-dashoffset 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-compare-toggle__tick-path.hover-tick {
  stroke: #ccc;
}

.c-compare-toggle__tick-path.active-tick {
  stroke: #21a3fc;
}

/* === THEME === */
.c-compare-btn--w-auto {
  width: auto;
}
.c-compare-btn--sm {
  font-size: 14px !important;
}

/* === THEME - LIGHT === */
.c-compare-btn--light .c-compare-toggle__rect-obj,
.c-compare-btn--light .c-compare-toggle__tick-path.hover-tick {
  stroke: #ffffff;
}
.c-compare-btn--light.c-compare-btn:hover {
  color: #9cd7ff;
}
.c-compare-btn--light.c-compare-btn:hover .c-compare-toggle__box-line.last {
  stroke: #9cd7ff;
}
.c-compare-btn--light.c-compare-btn--in-cart {
  color: #9cd7ff;
}
.c-compare-btn--light:hover .c-compare-toggle__tick-path.hover-tick {
  stroke: #9cd7ff;
}
.c-compare-btn--light .c-compare-toggle__tick {
  background: #fff;
}

/* ADDED */
.c-compare-btn--light.c-compare-btn--added .c-compare-toggle__rect-obj,
.c-compare-btn--light.c-compare-btn--added
  .c-compare-toggle__tick-path.active-tick {
  stroke: #21a3fc;
}

/* ADDED HOVER */
.c-compare-btn--light.c-compare-btn--added:hover {
  color: #fff;
}

.c-compare-btn--light.c-compare-btn--added:hover
  .c-compare-toggle__box-line.first {
  stroke: #3ed14a;
}

.c-compare-btn--light.c-compare-btn--added:hover
  .c-compare-toggle__box-line.last {
  stroke: #4db6ff;
}

.c-compare-btn--light.c-compare-btn--added:hover
  .c-compare-toggle__tick-path.active-tick {
  stroke: #9cd7ff;
}

/* COMPARE CARD PAGE /comparison
======================================================= */
/* ==== collapse-btn ==== */
.c-compare__collapse-btn {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 1rem;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: #052f5e;
  border-bottom: 2px solid #052f5e;
}
.c-compare__collapse-btn--sm {
  font-size: 13px;
}
@media (min-width: 375px) {
  .c-compare__collapse-btn--sm {
    font-size: 15px;
  }
}
@media (min-width: 480px) {
  .c-compare__collapse-btn,
  .c-compare__collapse-btn--sm {
    font-size: 18px;
  }
  .c-compare__collapse-btn {
    padding-left: 0;
    font-size: 18px;
    border-bottom: 3px solid #052f5e;
  }
}

/* ==== row ==== */
.c-compare__row {
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
}

.c-compare__row--has-header {
  padding-top: 2.5rem;
}

.c-compare__row-header {
  margin-top: -2.5rem;
}

.c-compare__row-header-border {
  border-bottom: 1px solid red;
}

/* ==== header ==== */
.c-compare__header-icon {
  transition: all 0.25s ease-in-out;
}

/* ==== cell ==== */
.c-compare__cell {
  flex: none;
  width: 50%;
  padding: 0.5rem 1rem;
  border-right: 1px solid transparent;
  font-size: 14px;
  color: #4a5568;
}
.c-compare__row {
  min-height: 3rem;
}
.c-compare__cell-wrap.hidden {
  display: none !important;
}
.c-compare__cell-wrap {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.c-compare__cell.c-compare__cell--card-tile:first-child {
  border-left: 1px solid #edf2f7;
}
.c-compare__cell.c-compare__cell--card-tile {
  border-right: 1px solid #edf2f7;
}

/* ==== themes for cells ==== */
.t-compare-1-col .c-compare__cell {
  width: 100%;
}
.t-compare-1-col .c-compare__rec-credit {
  width: 91.666667%;
}
.t-compare-1-col .c-compare__rec-credit-icon {
  display: inline-block;
}
.t-compare-1-col .c-card-art__container,
.t-compare-1-col .c-btn {
  max-width: 14rem;
}
.c-compare__card-title {
  font-size: 16px;
}

.t-compare-2-col .c-compare__cell {
  width: 50%;
}
.t-compare-2-col .c-compare__card-title {
  font-size: 14px;
}

.t-compare-3-col .c-compare__card-title,
.t-compare-4-col .c-compare__card-title {
  font-size: 14px;
}
.t-compare-3-col .c-compare__inner,
.t-compare-4-col .c-compare__inner {
  overflow: auto;
}
.t-compare-3-col .c-compare__cell,
.t-compare-4-col .c-compare__cell {
  display: table-cell;
  width: 160px;
}
.t-compare-3-col .c-compare__row,
.t-compare-4-col .c-compare__row {
  width: 100%;
  clear: left;
  display: table;
  table-layout: fixed;
}
.t-compare-3-col .c-compare__row.hidden,
.t-compare-4-col .c-compare__row.hidden {
  display: none;
}
.t-compare-3-col .c-compare__inner,
.t-compare-4-col .c-compare__inner {
  width: auto;
}
.t-compare-3-col .c-compare__cell-wrap,
.t-compare-4-col .c-compare__cell-wrap {
  float: left;
  display: table;
  table-layout: fixed;
}
.t-compare-3-col .c-compare__cell--card-tile .c-btn__lock,
.t-compare-4-col .c-compare__cell--card-tile .c-btn__lock {
  display: none;
}
/*
.t-compare-3-col .c-compare__container {
  overflow: scroll;
}
*/

.t-compare-2-col .c-card-art__container,
.t-compare-2-col .c-btn,
.t-compare-3-col .c-card-art__container,
.t-compare-3-col .c-btn,
.t-compare-4-col .c-card-art__container,
.t-compare-4-col .c-btn {
  width: 90%;
}

/* ==== media ==== */
@media (min-width: 375px) {
  .c-compare__cell {
    padding: 0.75rem 1rem;
  }
}

@media (min-width: 480px) {
  .c-compare__cell {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .c-compare__card-title {
    font-size: 21px;
  }

  .t-compare-2-col .c-compare__card-title {
    font-size: 16px;
  }

  .t-compare-1-col {
    padding-bottom: 6rem;
  }

  .t-compare-1-col .c-card-art__container,
  .t-compare-1-col .c-btn {
    max-width: 16rem;
  }

  .t-compare-2-col .c-card-art__container,
  .t-compare-2-col .c-btn {
    width: 80%;
  }

  .t-compare-3-col .c-card-art__container,
  .t-compare-3-col .c-btn,
  .t-compare-4-col .c-card-art__container,
  .t-compare-4-col .c-btn {
    width: 90%;
  }

  .t-compare-3-col .c-compare__container,
  .t-compare-4-col .c-compare__container {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@media (min-width: 640px) {
  .t-compare-2-col .c-compare__rec-credit-icon {
    display: inline-block;
  }

  .t-compare-3-col .c-compare__cell--card-tile .c-btn__lock {
    display: inline-block;
  }

  .c-popup__arrow:after {
    right: 64px;
  }
  .c-popup__arrow:before {
    right: 62px;
  }

  .t-compare-2-col .c-card-art__container,
  .t-compare-2-col .c-btn,
  .t-compare-3-col .c-card-art__container,
  .t-compare-3-col .c-btn {
    width: 75%;
  }

  .t-compare-4-col .c-compare__cell {
    width: 25%;
  }
}

@media (min-width: 768px) {
  .c-compare__card-title {
    font-size: 20px;
  }
  .t-compare-2-col .c-compare__rec-credit {
    width: 91.666667%;
  }
  .t-compare-2-col .c-card-art__container,
  .t-compare-2-col .c-btn {
    width: 70%;
  }

  .t-compare-3-col .c-compare__card-title {
    font-size: 16px;
  }
  .t-compare-3-col .c-card-art__container,
  .t-compare-3-col .c-btn {
    width: 83.333333%;
  }
  .t-compare-3-col .c-compare__cell {
    width: 33.333333%;
  }
  .t-compare-3-col .c-compare__container {
    margin-left: 0;
    margin-right: 0;
  }
  .t-compare-3-col .c-compare__card-title {
    font-size: 18px;
  }

  .t-compare-4-col .c-card-art__container,
  .t-compare-4-col .c-btn {
    width: 85%;
  }
}

@media (min-width: 1024px) {
  .c-compare__card-title {
    font-size: 21px;
  }

  .t-compare-2-col .c-card-art__container,
  .t-compare-2-col .c-btn {
    width: 50%;
  }
  .t-compare-2-col .c-compare__card-title {
    font-size: 21px;
  }

  .t-compare-3-col .c-card-art__container,
  .t-compare-3-col .c-btn {
    width: 70%;
  }

  .t-compare-4-col .c-compare__container {
    margin-left: 0;
    margin-right: 0;
  }
  .t-compare-4-col .c-card-art__container,
  .t-compare-4-col .c-btn {
    width: 80%;
  }
  .t-compare-4-col .c-btn {
    font-size: 16px;
  }
  .t-compare-4-col .c-compare__cell--card-tile .c-btn__lock {
    display: inline-block;
  }
}

@media (min-width: 1152px) {
  .t-compare-4-col .c-compare__card-title {
    font-size: 16px;
  }
}

/* ==== CARD NAV FOR COMPARISON PAGE ==== */

.c-card-nav {
  display: none;
  opacity: 0;
}
.c-card-nav.is-shown {
  display: block;
  animation-name: fadeInOpacity, animateCompareNavTop;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.25s;
}
.c-card-nav__wrap {
  min-height: 4rem;
}
.c-card-nav__cta {
  width: 7rem;
}
.c-card-nav__cell {
  min-height: 4rem;
  padding: 0.25rem 1rem;
}
.t-card-nav-1-col .c-card-nav__container {
  max-width: 28rem;
}
.c-card-nav__cell {
  border-right: 1px solid #edf2f7;
}
.c-card-nav__cell:last-child {
  border-right: none;
}

.t-card-nav-2-col .c-card-nav__cell {
  display: table-cell;
  width: 50%;
}

.t-card-nav-2-col .c-card-nav__wrap,
.t-card-nav-3-col .c-card-nav__wrap,
.t-card-nav-4-col .c-card-nav__wrap {
  float: left;
  display: table;
  table-layout: fixed;
}
.t-card-nav-3-col .c-card-nav__cell,
.t-card-nav-4-col .c-card-nav__cell {
  display: table-cell;
  width: 160px;
}

@media (min-width: 480px) {
  .c-card-nav__cta {
    width: 8rem;
  }
  .t-card-nav-2-col .c-card-nav__container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 768px) {
  .t-card-nav-1-col .c-card-nav__container {
    max-width: none;
    margin-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .t-card-nav-1-col .c-card-nav__wrap {
    width: 50%;
    margin-left: 0;
    border-right: 1px solid #edf2f7;
  }

  .t-card-nav-3-col .c-card-nav__container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .t-card-nav-3-col .c-card-nav__inner,
  .t-card-nav-4-col .c-card-nav__inner {
    overflow: visible;
  }
}

@media (min-width: 1024px) {
  .t-card-nav-1-col .c-card-nav__container {
    max-width: 72rem;
    margin: auto;
  }
  .c-card-nav__cell {
    min-height: 5rem;
  }
  .t-card-nav-4-col .c-card-nav__container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1152px) {
  .c-card-nav__wrap {
    border-left: 1px solid #edf2f7;
    border-right: 1px solid #edf2f7;
  }
  .t-card-nav-1-col .c-card-nav__container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* COMPARISON PAGE - Styling for the comparison page
  ======================================================= */
@media (max-width: 639px) {
  .c-comparison-pg .c-rec-credit__icon {
    display: none;
  }
  .c-comparison-pg .c-rec-credit__text {
    margin-bottom: 0.25rem;
  }
}

/* ARTICLE-LINK - Used for links to recommended articles
  ======================================================= */
/* On hover changes the text color and enlarges image  */
.c-article-link:hover .c-article-link__img {
  @apply scale-105;
}
.c-article-link:hover .c-article-link__title {
  @apply text-primary-bright;
}

/* MODAL: General modal classes
  ======================================================= */
/*===== CREDIT MODAL =====*/
.c-modal {
}
.c-modal__bg {
  background-color: rgba(2, 22, 45, 0);
}
.c-modal__container {
  overflow: hidden;
}
.c-modal__container,
.c-modal__bg {
  opacity: 0;
}

/* CENTER MODAL TO WINDOW */
@media (min-height: 667px) {
  .c-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/*===== MODAL THEMES =====*/
/* MODAL-IN */
.c-modal.modal-in .c-modal__container {
  animation-name: fadeInOpacity, animateTopNorth;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.c-modal.modal-in .c-modal__bg {
  animation-name: fadeInOpacity;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  background-color: rgba(2, 22, 45, 0.8);
}

/* SHOPPER APPROVED
  ======================================================= */

/**
Example css for a popup survey.
*/

/* Change the font and font-size here */
#shopper_approved {
  z-index: 32768;
  font:
    14px --font-open-sans,
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif !important;
  color: black !important;
  display: none;
  position: absolute;
  width: 100%;
}

#outer_shopper_approved > #shopper_background {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 32767 !important;
  background-color: #333333 !important;
  opacity: 0.4 !important;
  filter: alpha(opacity=40) !important;
}

/* Outer dimensions (max) */
#shopper_approved #sa_outer {
  margin: 20vh auto 1rem !important;
  max-width: 512px !important;
  padding: 0 2rem !important;
}

#outer_shopper_approved #shopper_approved > #sa_outer {
  max-width: 512px !important;
}

/* The popup box itself. (Here we make it rounded.) */
#shopper_approved #sa_rounded {
  background-color: white !important;
  padding: 0 0 20px 0 !important;
  border: 1px solid #ccc !important;
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -khtml-border-radius: 5px !important;
  border-radius: 5px !important;
  max-height: calc(100vh - 128px) !important;
  overflow-y: auto !important;
}

/* Thank you... text. You may use this area for a background image to add more customization. */
#shopper_approved #sa_header {
  border: 0 !important;
  text-align: left !important;
  padding: 20px 0 0 30px !important;
  vertical-align: middle !important;
  font-style: normal !important;
  font-size: 24px !important;
  color: #2252bc;
  font-family: --font-open-sans, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

/* The stars. Feel free to download this image, alter it and upload it back to your site for customization. */
.sa_star {
  cursor: pointer;
  background: url('https://www.shopperapproved.com/thankyou/simplestar.png')
    no-repeat !important;
  width: 24px !important;
  height: 24px !important;
  display: inline-block;
}

/* This class is assigned to active (gold) stars. In this case it slides our background image down. */
.sa_activestar {
  background-position: 0 -24px !important;
}

/* The comment box. */
#shopper_approved select,
input,
textarea {
  font-size: 16px !important;
}

/* Text warning them about low ratings. */
#sa_lowrating {
  padding: 20px 0 !important;
  display: none;
  font:
    16px arial,
    sans-serif !important;
  color: red !important;
}

#sa_lowrating a {
  font:
    16px arial,
    sans-serif !important;
  color: blue !important;
  cursor: pointer;
}

/* Text after they clicked no to opt in. */
#sa_last_chance {
  display: none;
  font-size: smaller !important;
  color: red !important;
  padding: 0 0 6px 0 !important;
}

#shopper_submit {
  border: inherit !important;
  padding: inherit !important;
  background-color: transparent !important;
  color: transparent !important;
  background-image: url(https://www.shopperapproved.com/thankyou/images/submit-feedback.png) !important;
  width: 175px !important;
  height: 40px !important;
}

#shopper_submit:hover {
  cursor: pointer;
}

#shopper_approved #sa_close {
  border: 0 !important;
  display: block !important;
  float: right !important;
  margin: -10px 10px 0 0 !important;
  cursor: pointer;
}

#shopper_approved #sa_ratings {
  text-align: left !important;
  padding: 0 30px !important;
}

#shopper_approved #sa_thankyou {
  text-align: left !important;
  padding: 0 30px !important;
}

#shopper_approved #sa_thankyou_no_email {
  text-align: center !important;
  padding: 0 30px !important;
}

#shopper_approved .sa_ratingBox {
  padding: 20px 0 0 0 !important;
  font:
    14px arial,
    sans-serif !important;
  color: black !important;
}

#shopper_approved select.sa_value {
  width: 200px !important;
  margin-top: 7px !important;
}

#sa_comments {
  padding: 20px 0 !important;
  display: none;
  font:
    16px arial,
    sans-serif !important;
}

#shopper_approved textarea {
  border: 1px solid #bbb !important;
  color: gray;
  padding: 2px;
  width: 100% !important;
}

#sa_optin {
  padding: 0 !important;
  display: none;
  font:
    16px arial,
    sans-serif !important;
  color: black !important;
}

#sa_optin input[type='text'] {
  border: 1px solid #bbb !important;
  width: 300px !important;
  color: black !important;
  float: right;
  margin-right: 50px;
}

#sa_optin .sa_optin {
  padding: 10px 0 !important;
}

#shopper_approved #sa_footer_img {
  float: right !important;
  display: block !important;
}

#sa_safeemail {
}

#sa_safe {
  font-weight: bold;
}

/* Media queries for responsiveness. */
@media (max-width: 600px) {
  #sa_optin input[type='text'] {
    margin-right: 0 !important;
  }
}

@media (max-width: 540px) {
  #sa_optin input[type='text'] {
    margin-right: 0 !important;
    width: 200px !important;
  }
}

@media (max-width: 500px) {
  #outer_shopper_approved > #shopper_approved > #sa_outer {
    margin: 12vh 0 0 0 !important;
    padding: 1rem 2rem !important;
  }
}

select,
input,
textarea {
  font-size: 16px !important;
}

/* ==========================================================================
   Override styling coming from API
   ========================================================================== */

#sa_comments,
#shopper_approved > *,
#sa_optin,
#shopper_approved .sa_ratingBox {
  font-family: --font-open-sans, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

#shopper_approved .sa_optin input[type='text'] {
  margin-right: 0 !important;
  width: 100% !important;
  max-width: 300px !important;
}

#shopper_approved input[type='radio']::after {
  position: absolute;
  top: 1px;
  left: 4px;
  z-index: 1;
}

#shopper_approved input.sa_followup[type='radio']:checked::before {
  left: 1px !important;
  z-index: 2;
}
h #sa_tooptin label {
  margin-right: 1rem;
}

#sa_tooptin label:last-child {
  margin-right: 0;
}

.sa_thankyou_text {
  font-size: 18px;
  color: #00cc7a;
}

/* EVEN FORM
  ======================================================= */

.c-form-popup .c-popup__arrow:after,
.c-form-popup .c-popup__arrow:before {
  left: 1.25rem;
}

.c-form-popup__container {
  width: 18rem;
}

@media (min-width: 480px) {
  .c-form-popup__container {
    width: 22rem;
  }
}

/* IMPRESSION TAG AT THE BOTTOM OF THE PAGE
  ======================================================= */
.impression-tag {
  width: 1px !important;
  height: 1px !important;
}

/* EXPERIMENT - STATSIG
  ======================================================= */
/* 2023.10: EXP-205 Who Should Get Top */
.t-content-v1 {
  display: none;
}
.t-content-v1.t-content-v1--show {
  display: block;
}
.t-content-base.t-content-v1--show {
  display: none;
}

/* 2023.12: EXP-234 CTA Icon Site Wide */
.t-234 .c-card-art__icon,
.t-234 .c-btn__icon {
  display: none !important;
}

/* 2024.1: EXP-204 Other Cards */
.c-related-cards--tile.c-related-cards--v1 {
  display: block !important;
}
.c-related-cards--list.c-related-cards--v2 {
  display: block !important;
}

/* 2024.9: EXP-324 Travel Category - Venture Rewards Banner */
.c-alert-upsell.flex {
  display: none;
}
.c-layout-main--324-control .c-alert-upsell.flex {
  display: flex;
}
.c-layout-main--324-v1 .c-main__container {
  padding-top: 4rem;
}
@media (min-width: 1024px) {
  .c-layout-main--324-v1 .c-main__container {
    padding-top: 5rem;
  }
}

/* ===== 2024.11: EXP-161 Navbar slim and hide exp_161_hide_nav_bar ===== */
/* c-navbar--161-v2 No fixed nav */
.c-navbar--161-v2 .c-navbar-container {
  position: relative;
}
.c-navbar--161-v2 .c-main__container {
  padding-top: 0.25rem;
}
@media (min-width: 1024px) {
  .c-navbar--161-v2 .c-card-aside--fixed .c-card-aside__row {
    padding-top: 0.5rem !important;
  }
}

/* TAILWIND: Components and Utility Classes
  ======================================================= */
@tailwind components;
@tailwind utilities;

/* Additional Utilities
  ======================================================= */
/*============================================================================
  Skip to content button
    - Overrides .visually-hidden when focused
==============================================================================*/
.c-skip-link:focus {
  clip: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: auto;
  max-width: 12.5rem;
  height: auto;
  margin: 0.5rem 0 0 0.5rem;
  padding: 0.5rem 1.5rem;
  color: #2d3748;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  opacity: 1;
}

.mb-lc-0 > :last-child {
  margin-bottom: 0;
}

sup {
  font-family: --font-open-sans, sans-serif;
  font-size: 60%;
  font-weight: normal;
}

.text-shadow-base {
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.c-slidedown {
  opacity: 1;
  max-height: 140rem;
  transition: all 0.25s ease-in-out;
  overflow: visible;
}

/* is-closed */
.c-slidedown--hidden {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.w-full-vw {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
}

.h-screen-nav {
  min-height: calc(100vh - 4rem);
}

@media (min-width: 1024px) {
  .c-h-screen-nav {
    min-height: calc(100vh - 5rem);
  }
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.transition-all {
  transition-property: all;
  transition-duration: 0.15s;
}

.transition-25s {
  transition-property: all;
  transition-duration: 0.25s;
}

.transition-all--25 {
  transition-property: all;
  transition-duration: 0.25s;
}

.transition-none {
  transition-property: none;
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
}

.scrollbar-none {
  -ms-overflow-style: none;
  /* IE */
  scrollbar-width: none;
  /* Firefox */
}

/* Link to Redirect Modal Trigger */
.c-ltr-modal-trigger {
  display: block;
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
.c-list-disc__item .c-ltr-modal-trigger {
  display: inline-block;
  width: auto;
}

/* allows for responsive videos */
.c-video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.c-video-wrapper iframe,
.c-video-wrapper .c-video-wrapper__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loan-description > span > ul li::before {
  content: '\2022';
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  @apply text-primary-bright;
}

.loan-description > span > ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.loan-description > span > ul > li {
  margin-bottom: 0.5rem;
}

/* SKELETON LOADER BG
  ======================================================= */
.c-content-placeholder-bg {
  position: relative;
  overflow: hidden;
}
.c-content-placeholder-bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 70%;
  height: 100%;
  will-change: transform;
  background: -moz-linear-gradient(
    left,
    rgba(26, 32, 44, 0) 0%,
    rgba(26, 32, 44, 1) 50%,
    rgba(255, 255, 255, 0.3) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(26, 32, 44, 0) 0%,
    rgba(26, 32, 44, 1) 50%,
    rgba(255, 255, 255, 0.3) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(26, 32, 44, 0) 0%,
    rgba(26, 32, 44, 1) 50%,
    rgba(255, 255, 255, 0.3) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: unquote(
    "progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a202c', endColorstr='#00ffffff',GradientType=1 )"
  );
  opacity: 0.04;
  animation: content-placeholder-animation 1.35s linear infinite;
}
@keyframes content-placeholder-animation {
  0% {
    transform: translateX(-80%);
  }
  100% {
    transform: translateX(200%);
  }
}

/* ANIMATIONS = General keyframes
  ======================================================= */
@keyframes animateTopNorth {
  from {
    transform: translateY(2rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes animateTopNorthRelative {
  from {
    top: 2rem;
  }
  to {
    top: 0;
  }
}
@keyframes fadeInOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* ===== COMPARISON PAGE NAV ===== */
@keyframes animateCompareNavTop {
  from {
    transform: translateY(-10rem);
  }
  to {
    transform: translateY(0);
  }
}
